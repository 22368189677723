<template>
  <div class="NewHome">
    <!-- <div class="listload" ref="containerRef" @scroll="handleScroll"> -->
    <div class="listload">
      <!-- <van-list v-model:loading="loading" :finished="finished" finished-text="没有更多了" :offset="1000" @load="onLoad"> -->
      <div class="topdata">
        <div class="title">我的余额（元）</div>
        <div class="text">
          <div class="textleft">
            <div class="value">{{ my_balance }}</div>

            <div class="tipvalue">含冻结金额：{{ frozen_amount }}<span></span></div>
            <div class="tipvalue">可提现金额：{{ withdraw_amount }}<span></span></div>
          </div>
          <div class="textright">
            <div class="wztext">我的设备</div>
            <div class="math">总{{ total_device }}/在线{{ online_device }}</div>
          </div>
        </div>
      </div>
      <div class="icondata">
        <div class="item">
          <div class="cont">
            <div class="tit">今日收益(元)</div>
            <div class="math">
              <div class="icon"><img src="@/assets/img/dataicon1.png" alt=""></div>
              <div class="text">{{ today_income }}</div>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="cont">
            <div class="tit">今日流水(元)</div>
            <div class="math">
              <div class="icon"><img src="@/assets/img/dataicon2.png" alt=""></div>
              <div class="text">{{ today_flow }}</div>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="cont">
            <div class="tit">今日订单数</div>
            <div class="math">
              <div class="icon"><img src="@/assets/img/dataicon3.png" alt=""></div>
              <div class="text">{{ today_orders }}</div>
            </div>
          </div>
        </div>
        <div class="item" v-if="false">
          <div class="cont">
            <div class="tit">今日活动收益(元)</div>
            <div class="math">
              <div class="icon"><img src="@/assets/img/dataicon1.png" alt=""></div>
              <div class="text">{{ today_activity_income }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="menuicons">
        <div class="item" v-for="(item, index) in PageData.nav" :key="index" @click="toPageRout(item.val)">
          <div class="icon"><img :src="item.icon" alt=""></div>
          <div class="text">{{ item.title }}</div>
        </div>
      </div>
      <Profile />
      <div class="outlinelist">
        <div class="title">
          <div class="text">门店概览<span>{{ off_device_now }}</span></div>
          <div class="dayrange" @click="showClick">
            <div class="icon"><img src="@/assets/img/dayicon.png" alt=""></div>
            <div class="text">{{ dayrangeText ? dayrangeText : '请选择日期' }}</div>
          </div>
          <div class="dayrange" @click="storeFieldShowClick">
            <div class="icon"><img src="@/assets/img/field.png" alt=""></div>
            <div class="text">字段</div>
          </div>
        </div>
        <div class="listcont">
          <div class="thead">
            <template v-for="(item, index) in storeField" :key="index">
              <div class="item" v-if="!item.filter || item.checked">{{item.title}}</div>
            </template>
          </div>
          <div class="tbody">
            <div class="item" v-for="(item, index) in list" :key="index" @click="todtail(item.more_url)">
              <template v-for="(fitem, findex) in storeField" :key="findex">
                <div class="contitem" v-if="!fitem.filter || fitem.checked">{{ item[fitem.field] }}
                  <van-icon class="icon" size="16px" name="arrow" />
                </div>
              </template>
            </div>
          </div>
        </div>
        <div class="bottombtn"><button type="button" @click="toMoreClick">查看更多</button></div>
      </div>
      <!-- </van-list> -->

      <!-- <div class="loading" v-if="loading"><img src="@/assets/img/loading.gif" alt="">加载中。。。</div>
      <div class="finish" v-if="finished">没有更多了</div> -->
    </div>
    <van-popup v-model:show="selectPupShow" position="bottom" round>
      <van-picker :title="pupData[1].title" :columns="pupData[0].data" :columns-field-names="dataType"
        :default-index="pickdef" @confirm="okClick" @cancel="Cancel" />
    </van-popup>

    <van-calendar v-model:show="dayPupShow" type="range" :min-date="new Date(2010, 1, 1)" :show-confirm="false"
      :allow-same-day="true" @confirm="onConfirm" />

    <van-popup v-model:show="showAct" position="center" style="background:none" round>
      <div class="actFlowpic">
        <div class="pic" @click="toActPage"><img :src="actPic" alt=""></div>
        <div class="close" @click="closeClick"><img src="@/assets/img/searchclear.png" alt=""></div>
      </div>
    </van-popup>

    <van-popup v-model:show="storeFieldShow" position="bottom" round>
      <div class="titlebox">
<!--        <button type="button" @click="storeFieldCancer">取消</button>-->
        <div class="title">筛选字段</div>
        <button type="button" @click="storeFieldOk">确认</button>
      </div>
      <div class="checkcont">
        <div class="checkfielddesc">已选字段<span>(至少保留一个)</span></div>
        <template v-for="(item, index) in tmpCheckObj" :key="index">
          <van-checkbox v-model="item.checked" @click="storeFieldChange(index)">{{item.title}}</van-checkbox>
        </template>
      </div>
    </van-popup>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import mitts from "@/bus";
import { Toast } from "vant";
import API from "@/axios";
import { useRouter } from "vue-router";
import Profile from "@/components/OrderManage/profile"
const router = useRouter();

const date_range = ref("")
const PageData = ref({})
const pickdef = ref()
const off_device_now = ref("")

const frozen_amount = ref("")
const my_balance = ref("")
const online_device = ref("")
const today_flow = ref("")
const today_income = ref("")
const today_orders = ref("")
const total_device = ref("")
const withdraw_amount = ref("")
const today_activity_income = ref("")

const list = ref([]);
// const loading = ref(false);
// const finished = ref(true);

const page = ref(1)
const date_type = ref("")
const store_date_range = ref("")
const dayrangeText = ref("")
const selectPupShow = ref(false)
const dayPupShow = ref(false)
const pupData = ref({})

function showClick() {
  selectPupShow.value = true
}

function okClick(val, index) {
  selectPupShow.value = false
  pickdef.value = index
  if (val.val == "diy") {
    dayPupShow.value = true;
  } else {
    list.value = []
    page.value = 1
    date_type.value = val.val
    dayrangeText.value = val.title
    store_date_range.value = ""

    getStoreSummarize()
  }
}

function Cancel() {
  selectPupShow.value = false
}

// 门店概况字段显示配置
const storeField = ref([{}])
const storeFieldShow = ref(false)
function storeFieldShowClick() {
  storeFieldShow.value = true;
}

// function storeFieldCancer() {
//   storeFieldShow.value = false;
// }

const storeFieldStorageName = ref('storeSummarizeField')
function storeFieldOk() {
  storeFieldShow.value = false;

  for (const [index, item] of Object.entries(storeField.value)) {
    if (tmpCheckObj.value[item.field]) {
      storeField.value[index]['checked'] = tmpCheckObj.value[item.field]['checked']
    }
  }

  localStorage.setItem(storeFieldStorageName, JSON.stringify(storeField.value))
}

const tmpCheckObj = ref({})
function storeFieldChange(index) {
  var checkNum = 0;
  for (const item of Object.entries(tmpCheckObj.value)) {
    if (item[1].checked) {
      checkNum = checkNum + 1
    }
  }

  if (checkNum < 1) {
    Toast.success('至少选择一个字段')
    tmpCheckObj.value[index].checked = !tmpCheckObj.value[index].checked
    return false
  }
}

const formatDate = (date) =>
  `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
const onConfirm = (values) => {
  const [start, end] = values;
  dayPupShow.value = false;
  store_date_range.value = `${formatDate(start)}~${formatDate(end)}`;
  dayrangeText.value = `${formatDate(start)}~${formatDate(end)}`;
  list.value = []
  page.value = 1
  date_type.value = "diy"
  getStoreSummarize()
};

// const onLoad = () => {
//   getStoreSummarize()

//   finished.value = true;
//   loading.value = false;
// };

const dataType = ref({
  text: 'title',
  val: 'val',
})

function homeIni() {
  API({
    url: "/api/h5Home",
    method: "post",
    data: {
      date_range: date_range.value,
    },
  }).then((res) => {
    if (res.data.status == 200) {
      PageData.value = res.data.data

      frozen_amount.value = PageData.value.money.frozen_amount;
      my_balance.value = PageData.value.money.my_balance;
      online_device.value = PageData.value.money.online_device;
      today_flow.value = PageData.value.money.today_flow;
      today_income.value = PageData.value.money.today_income;
      today_orders.value = PageData.value.money.today_orders;
      total_device.value = PageData.value.money.total_device;
      today_activity_income.value = PageData.value.money.today_activity_income;
      withdraw_amount.value = PageData.value.money.withdraw_amount;

      mitts.emit("secondMenus", [])
      //总线传参赋值底部菜单
      mitts.emit('navs', PageData.value.main_nav);
      mitts.emit("toptitle", true);
      mitts.emit('navindex', 'home');
    } else if (res.data.status == 10) {
      router.push({
        path: "/enter/404",
      });
    } else {
      Toast.fail(res.data.msg);
    }
  });
}

function toPageRout(val) {
  let URL = val.split("?url=");

  router.push({
    path: URL[0],
    query: { url: encodeURIComponent(URL[1]) },
  });
}

const onceget = ref(false)

const listPageData = ref(null)


function getStoreSummarize() {
  API({
    url: "/api/storeSummarize",
    method: "post",
    data: {
      page: page.value,
      date_type: date_type.value,
      date_range: store_date_range.value,
    },
  }).then((res) => {
    if (res.data.status == 200) {
      pupData.value = res.data.data.income_condition
      off_device_now.value = res.data.data.off_device_now
      storeField.value = res.data.data.field_condition

      let storageFields = localStorage.getItem(storeFieldStorageName)
      if (storageFields) {
        let fieldAtKey = 'storeSummarizeFieldAt'
        let fieldAt = localStorage.getItem(fieldAtKey)
        if (fieldAt == res.data.data.field_at) {
          storeField.value = JSON.parse(storageFields)
        } else {
          localStorage.setItem(fieldAtKey, res.data.data.field_at)
        }
      }

      storeField.value.forEach((item) => {
        if (item.filter) {
          tmpCheckObj.value[item.field] = {'checked': item.checked, 'title': item.title}
        }
      })

      if (onceget.value) {
        let valtext = res.data.data.income_condition[0].data
        for (const item of valtext) {
          if (item.checked == 1) {
            dayrangeText.value = item.title
          }
        }
      }

      onceget.value = false

      // loading.value = true
      // finished.value = false

      let newArr = [];
      list.value = list.value.concat(res.data.data.list);
      list.value.forEach((el) => {
        const result = newArr.findIndex((ol) => {
          return el.id === ol.id;
        });
        if (result !== -1) {
          newArr[result] = el;
        } else {
          newArr.push(el);
        }
      });

      listPageData.value = res.data.data.pages

      list.value = newArr;

    } else {
      Toast.fail(res.data.msg);
    }
  })
}

function todtail(val) {
  router.push({
    path: "/event/event",
    query: { url: encodeURIComponent(val) },
  });
}


const showAct = ref(false)
const actPic = ref("")

function closeClick() {
  showAct.value = false
}

function toActPage() {
  router.push({
    name: "activityPoster",
  });
}

function toMoreClick() {
  // 查看更多
  router.push({
    name: "storeOverview",
    query: {
      'navs': JSON.stringify(PageData.value.main_nav)
    }
  });
}

function entryConfig() {
  API({
    url: "/web/activity/entryConfig",
    method: "post",
    data: {
      search: {
        position: 1,
      }
    },
  }).then((res) => {
    if (res.data.status == 200) {
      actPic.value = res.data.data[0][0].img
      loadAD()

    } else {
      Toast.fail(res.data.msg);
    }
  });
}

function loadAD() {
  let lstamp = window.localStorage.getItem('timestamp');

  let tstamp = Date.parse(new Date());

  let tdata = TimestampToDate(tstamp)

  if (lstamp) {
    let ldata = TimestampToDate(lstamp)
    if (ldata !== tdata) {
      showAct.value = true
      window.localStorage.removeItem("timestamp");
      window.localStorage.setItem('timestamp', tstamp);
    } else {
      showAct.value = false
    }
  } else {
    let lstamp = Date.parse(new Date());
    let ldata = TimestampToDate(lstamp)
    window.localStorage.setItem('timestamp', ldata);
    showAct.value = true
  }

}

function TimestampToDate(Timestamp) {
  let now = new Date(Timestamp)
  return now.getFullYear() + "/" + (now.getMonth() + 1) + "/" + now.getDate()
}


entryConfig()


onMounted(() => {
  onceget.value = true

  homeIni();

  mitts.emit("toptitle", false);

  getStoreSummarize()

  setTimeout(() => {
    mitts.emit("closeLoading");
  }, 500)

})
</script>

<style lang="less" scoped>
.NewHome {
  height: 100%;
  box-sizing: border-box;
}

.listload {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.topdata {
  background: repeating-linear-gradient(to right, #4575df, #04cffa);
  padding: 50px 20px 100px 20px;

  .title {
    font-size: @subtitle-size;
    line-height: @subtitle-size;
    color: @font-white-color;
    margin-bottom: 30px;
  }

  .text {
    display: flex;
    justify-content: space-between;
    color: @font-white-color;

    .textleft {
      .value {
        font-size: 50px;
        line-height: 50px;
        font-weight: 600;
        margin-bottom: 10px;
      }

      .tipvalue {
        font-size: @font-size;
        line-height: @font-size;
        margin-bottom: 10px;
      }

      .tipvalue:last-of-type {
        margin-bottom: 0;
      }
    }

    .textright {
      display: flex;
      flex-flow: column;
      align-items: self-end;
      justify-content: flex-end;

      .wztext {
        font-size: @subtitle-size;
        line-height: @subtitle-size;
        margin-bottom: 20px;
      }

      .math {
        font-size: @font-size;
        line-height: @font-size;
      }
    }
  }
}

.icondata {
  position: relative;
  margin: 0 20px;
  background: @font-white-color;
  margin-top: -60px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px #d7d7d7;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  .item {
    display: flex;
    align-items: center;

    .cont {
      font-size: @font-size;
      line-height: @font-size;

      .tit {
        font-weight: 600;
        margin-bottom: 10px;
        font-size: 26px;
        line-height: 26px;
      }

      .math {
        color: @font-secondary-color;
        display: flex;
        align-items: center;

        .icon {
          width: 38px;
          margin-right: 10px;

          img {
            width: 100%;
            display: block;
          }
        }
      }
    }
  }
}

.menuicons {
  display: flex;
  padding: 20px 0;
  margin: 0 20px;
  background: @font-white-color;
  border-radius: 10px;
  box-shadow: 0 0 10px #d7d7d7;
  margin-bottom: 20px;

  .item {
    flex: 1;
    display: flex;
    flex-flow: column;

    .icon {
      width: 100px;
      margin: 0 auto;

      img {
        width: 100%;
        display: block;
      }
    }

    .text {
      text-align: center;
    }
  }
}

.outlinelist {
  background: @font-white-color;
  padding: 20px 20px 10px 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px #d7d7d7;
  margin: 0 20px;

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    border-bottom: 1px solid #dfdfdf;
    padding-bottom: 10px;

    .text {
      font-size: @subtitle-size;
      font-weight: bolder;
      font-family: "Regular";
      color: @font-color;
      line-height: @subtitle-size;
      margin-right: 10px;

      span {
        font-size: 24px;
        color: @font-third-color;
        line-height: 24px;
        font-weight: normal;
      }

    }

    .more {
      background: @theme-color;
      font-size: @fontmini-size;
      font-family: "Bold";
      color: @font-white-color;
      line-height: @fontmini-size;
      width: 120px;
      height: 50px;
      border-radius: 25px;
    }
  }

  .dayrange {
    display: flex;
    align-items: center;
    flex: 1;
    min-width: 0;
    justify-content: flex-end;

    .icon {
      width: 36px;
      margin-right: 10px;

      img {
        width: 100%;
        display: block;
      }
    }

    .text {
      font-size: 24px;
      line-height: 24px;
      color: @font-secondary-color;
      font-weight: normal;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .dayrange:last-of-type {
    flex: none;
    margin-left: 10px;
  }

  .listcont {
    overflow: hidden;

    .thead {
      display: flex;
      justify-content: space-between;
      padding: 20px 0;
      border-bottom: 1px solid #dfdfdf;

      .item {
        flex: 1;
        min-width: 0;
        font-size: @font-size;
        line-height: @font-size;
        margin-right: 10px;
        text-align: left;
        // text-overflow: ellipsis;
        // white-space: nowrap;
        // overflow: hidden;
      }

      .item:first-of-type {
        flex: 1;
      }

      .item:nth-of-type(2) {
        flex: none;
        width: @font-size*8;
        margin-right: 30px;
        text-align: center;
      }

      .item:last-of-type {
        margin-right: 0;
        flex: none;
        width: @font-size*5;
      }
    }

    .tbody {
      overflow: hidden;

      .item {
        display: flex;
        justify-content: space-between;
        padding: 20px 0;
        position: relative;

        .contitem {
          flex: 1;
          min-width: 0;
          font-size: @font-size;
          line-height: @font-size;
          margin-right: 10px;
          text-align: left;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          display: flex;
          justify-content: space-between;

          .icon {
            height: 14px;
            padding: 0;
            display: none;
          }
        }

        .contitem:first-of-type {
          flex: 1;
        }

        .contitem:nth-of-type(2) {
          flex: none;
          width: @font-size*8;
          margin-right: 30px;
          display: flex;
          justify-content: center;
        }

        .contitem:last-of-type {
          margin-right: 0;
          flex: none;
          width: @font-size*5;

          .icon {
            display: block;
          }
        }
      }
    }
  }
}

.loading {
  width: 100%;
  padding: 10px 0;
  font-size: 24px;
  line-height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 30px;
    display: block;
    margin-right: 10px;
  }
}

.finish {
  width: 100%;
  padding: 10px 0;
  font-size: 24px;
  line-height: 24px;
  display: flex;
  justify-content: center;
}

.actFlowpic {
  // position: fixed;
  // left: 50%;
  // top: 50%;
  // -webkit-transform: translate3d(-50%, -50%, 0);
  // transform: translate3d(-50%, -50%, 0);
  width: 60vw;
  display: flex;
  flex-flow: column;
  align-items: center;

  .pic {
    width: 100%;
    margin-bottom: 30px;

    img {
      width: 100%;
      display: block;
    }
  }

  .close {
    width: 60px;

    img {
      width: 100%;
      display: block;
    }
  }
}

.bottombtn {
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    line-height: 50px;
    height: 50px;
    font-size: 26px;
    background: none;
  }
}
.titlebox {
  display: flex;
  padding: 20px;
  .title {
    flex: 1;
    text-align: center;
  }
  button {
    background: none;
  }
}
.checkfielddesc {
  padding: 20px 0;

  span {
    color: #999999;
  }
}
</style>